cs.ns('app.ui.trait.root.hash')
app.ui.trait.root.hash.ctrl = cs.trait({
    dynamics: {
        directEntry: {
            hashtag: 't'
        }
    },
    protos:   {

        setup () {
            this.base()

            this.subscribeForChildEvent('directEntry', function (ev, channel, content) {
                const currentHash = content ? content.hash : (channel ? channel.hash : null)
                if (window && window.location) {
                    const hashMananger = app.HashManager.parseURL(window.location.hash)
                    if (hashMananger.hash(this.directEntry.hashtag) + '' !== currentHash + '') {
                        hashMananger.hash(this.directEntry.hashtag, currentHash)
                        this.model.value('state:automaticHashChange', true);
                        window.location.hash = hashMananger.toString();
                    }
                    this.model.value('global:data:qrcode', currentHash ? `${window.location.protocol}//${window.location.host}/#t=${currentHash}` : "x")
                }
            })
        },

        prepare () {
            this.base();

            this.observeOwnModel('event:hashChange', (ev, newURL) => {
                const oldAutomaticState = this.model.value('state:automaticHashChange', false);
                if (!oldAutomaticState) {
                    app.handleHashes();
                    this.handleHashChange(app.HashManager.parseURL(newURL))
                }
            })
        },

        show () {
            this.base()
            this.handleHashChange(app.HashManager.parseURL(window.location.hash))
        },

        handleHashChange(hashManager) {
            this.base(hashManager)

            this.initialize({
                hash:     hashManager.hash(this.directEntry.hashtag),
                autoplay: hashManager.hasHash('autoplay')
            })
        },

        initialize (options) {
            this.base(options)
        }

    }
})